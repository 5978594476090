a {
  color: #fff;
}

.footer_m_br {
  display: none;
}
.footer_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 1280px;
  height: 24.3125rem;
  background: #1d1d1d;
}
.footer_container a {
  text-decoration: none;
}
.footer_child_container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

.footer_text_container {
  text-align: right;
}

.footer_nav_container ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3.75rem;
  padding: 0;
}
.footer_nav_container ol {
  color: #fff;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 118.836%; /* 1.48544rem */
  text-align: center;
  padding: 0;
}
.footer_info_container {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 118.836%; /* 1.03981rem */
  text-decoration-line: underline;
  padding: 0;
  margin: 0;
  margin-top: 3.12rem;
}
.footer_info_container ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3.75rem;
  padding: 0;
  margin: 0;
  width: 473px;
}
.footer_info_container ul:lang(en) {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 0;
  margin: 0;
  width: 558px;
}
.footer_info_container ol {
  padding: 0;
  margin: 0;
}
.sns_icon {
  display: flex;
  gap: 3.75rem;
}
.sns_instagram {
  width: 25px;
  height: 25px;
  background-image: url("/public/image/pc/instagram.svg");
}
.sns_linkedin {
  width: 25px;
  height: 25px;
  background-image: url("/public/image/pc/linkedin.svg");
}
.footer_adress {
  color: #fff;
  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 118.836%; /* 1.03981rem */
  margin: 0;
  margin-top: 1.88rem;
  text-align: left;
}
.footer_copyright {
  color: #fff;
  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 118.836%; /* 1.03981rem */
  margin: 0;
  margin-top: 0.62rem;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .footer_m_br {
    display: block;
  }
  .footer_container {
    display: inline-block;
    width: 100%;
    min-width: auto;
    height: 26rem;
    background: #1d1d1d;
  }
  .footer_container:lang(en) {
    display: inline-block;
    width: 100%;
    min-width: auto;
    height: 463px;
    background: #1d1d1d;
  }
  .footer_container a {
    text-decoration: none;
  }
  .footer_child_container {
    display: inline-block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    padding-top: 1.25rem;
  }
  .footer_child_container img {
    display: none;
  }

  .footer_text_container {
    text-align: left;
  }

  .footer_nav_container ul {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-top: 1.25rem;
    margin-left: 1.25rem;
  }
  .footer_nav_container ol {
    color: #fff;
    font-family: Pretendard;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 118.836%; /* 0.96556rem */
    text-align: left;
    padding: 0;
    margin-top: 1.25rem;
  }
  .footer_info_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    font-family: Pretendard;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 118.836%; /* 0.96556rem */
    text-decoration-line: underline;
    padding: 0;
    margin: 0;
    margin-top: 1.25rem;
  }
  .footer_info_container ul {
    display: flex;
    justify-content: flex-start;
    gap: 1.69rem;
    padding: 0;
    margin: 0;
    margin-left: 1.25rem;
    width: auto;
  }
  .footer_info_container ul:lang(en) {
    display: flex;
    justify-content: flex-start;
    gap: 1.69rem;
    padding: 0;
    margin: 0;
    margin-left: 1.25rem;
    width: 217px;
    line-height: 20px;
  }
  .footer_info_container ol {
    color: #fff;
    font-family: Pretendard;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 118.836%; /* 0.89125rem */
    text-decoration-line: underline;
    padding: 0;
    margin: 0;
  }
  .sns_icon {
    display: flex;
    gap: 1.87rem;
    margin-left: 20px;
    margin-top: 20px;
  }
  .sns_instagram {
    width: 20px;
    height: 20px;
    background-image: url("/public/image/mobile/instagram.svg");
  }
  .sns_linkedin {
    width: 20px;
    height: 20px;
    background-image: url("/public/image/mobile/linkedin.svg");
  }
  .footer_adress {
    color: #fff;
    font-family: Pretendard;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 118.836%; /* 0.74275rem */
    margin: 0;
    margin-top: 1.25rem;
    margin-left: 1.25rem;
  }
  .footer_copyright {
    color: #fff;
    font-family: Pretendard;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 118.836%; /* 0.74275rem */
    margin: 0;
    margin-top: 0.63rem;
    margin-left: 1.25rem;
  }
}
