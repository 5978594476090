.contact_pc_br {
  display: block;
}
.contact_m_br {
  display: none;
}
.contact_us_container {
  padding-top: 8.4375rem;
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1280px;
  height: 54.19rem;
  margin-top: 6.87rem;
}
.contact_us_child_container {
  display: flex;
  justify-content: space-between;
  width: 66.66%;
  min-width: 1280px;
}
.contact_us_text_container h1 {
  margin: 0;
  color: #232323;
  font-family: Pretendard;
  font-size: 4.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contact_us_text_container h1:lang(en) {
  margin: 0;
  color: #232323;
  font-family: Pretendard;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contact_us_text_container p {
  margin: 0;
  margin-top: 3.75rem;
  color: #403d38;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact_us_form_container {
}
.contact_us_form_container h6 {
  margin: 0;
  margin-bottom: 0.63rem;
  color: #403d38;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.contact_us_input_container input {
  width: 39.375rem;
  height: 3rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-radius: 0.9375rem;
  border: 2px solid #c6c6c6;
  background: #fff;
  /* color: #777; */
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact_us_form_container textarea {
  width: 39.375rem;
  height: 3rem;
  padding-top: 0.69rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-radius: 0.9375rem;
  border: 2px solid #c6c6c6;
  background: #fff;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  resize: none;
}
.contact_us_input_container {
  margin-bottom: 1.56rem;
}
.contact_us_marketing_container {
  display: flex;
  margin-top: 0.94rem;
}
.contact_us_marketing_container p {
  margin: 0;
  color: #343434;
  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact_us_info_container {
  display: flex;
}
.contact_us_info_container p {
  margin: 0;
  color: #343434;
  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact_us_form_container button {
  width: 100%;
  height: 3rem;
  margin-top: 1.87rem;
  border: 2px solid #ff9000;
  border-radius: 0.9375rem;
  background: #fff;
  color: #ff9000;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.contact_us_form_container button:hover {
  color: #fff;
  background: #ff9000;
  border: none;
}
.input_error {
  color: #f3213b;
  font-size: 0.8rem;
  margin-top: 5px;
}
.loading-container {
  position: fixed; /* 고정 위치 */
  top: 50%; /* 상단에서부터 화면의 절반 */
  left: 50%; /* 왼쪽에서부터 화면의 절반 */
  transform: translate(-50%, -50%); /* 정확한 중앙 정렬 */
  z-index: 1000; /* 다른 요소들 위에 표시되도록 z-index 설정 */
}

@media screen and (max-width: 768px) {
  .contact_pc_br {
    display: none;
  }
  .contact_m_br {
    display: block;
  }
  .contact_us_container {
    padding-top: 8.4375rem;
    display: block;
    justify-content: center;
    width: 100%;
    min-width: auto;
    height: auto;
    margin: 0;
  }
  .contact_us_child_container {
    display: block;
    justify-content: space-between;
    width: 100%;
    min-width: auto;
  }
  .contact_us_text_container {
    padding: 0 1.25rem;
  }
  .contact_us_text_container h1 {
    margin: 0;
    color: #232323;
    font-family: Pretendard;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .contact_us_text_container h1:lang(en) {
    margin: 0;
    color: #232323;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .contact_us_text_container p {
    margin: 0;
    margin-top: 1.25rem;
    color: #232323;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contact_us_form_container {
    box-sizing: border-box;
    margin: auto;
    margin-top: 3.75rem;
    width: 100%;
    padding: 0 1.25rem;
    /* border: 1px solid red; */
  }
  .contact_us_form_container h6 {
    margin: 0;
    margin-bottom: 0.63rem;
    color: #403d38;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .contact_us_input_container input {
    box-sizing: border-box;
    width: 100%;
    height: 3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-radius: 0.625rem;
    border: 2px solid #c6c6c6;
    background: #fff;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contact_us_form_container textarea {
    box-sizing: border-box;
    width: 100%;
    height: 3rem;
    padding-top: 0.69rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-radius: 0.9375rem;
    border: 2px solid #c6c6c6;
    background: #fff;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    resize: none;
  }
  .contact_us_input_container {
    margin-bottom: 1.88rem;
  }
  .contact_us_marketing_container {
    display: flex;
    margin-top: 0.62rem;
  }
  .contact_us_marketing_container p {
    margin: 0;
    color: #343434;
    font-family: Pretendard;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contact_us_info_container {
    display: flex;
  }
  .contact_us_info_container p {
    margin: 0;
    color: #343434;
    font-family: Pretendard;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contact_us_form_container button {
    width: 100%;
    height: 3rem;
    margin-top: 1.88rem;
    margin-bottom: 6.25rem;
    border: none;
    border-radius: 0.9375rem;
    background: #ff9000;
    color: #fff;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
