.brochure_m_br {
  display: none;
}
.brochure_pc_br {
  display: block;
}
.brochure_child_container {
  position: absolute;
  top: 435px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-width: 1280px;
  padding: 0;
}
.brocure_logo img {
  width: 304px;
  height: 236px;
}
.brochure_text {
  padding: 0;
}

.brochure_child_container h3 {
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.brochure_child_container h3:lang(en) {
  color: #fff;
  text-align: center;
  font-family: Prata;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.brochure_child_container p {
  color: #fefefe;
  text-align: center;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.knowhow_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: 1280px;
  height: 31.25rem;
  background: #2e2e2e;
}
.knowhow_container a {
  text-decoration: none;
}
.knowhow_child_container {
  padding: 0 20px;
}
.knowhow_text {
  width: 745px;
}
.knowhow_child_container h6 {
  margin: 0;
  margin-bottom: 1.88rem;
  color: #e1e1df;
  font-family: Pretendard;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.knowhow_child_container h6:lang(en) {
  margin: 0;
  margin-bottom: 1.88rem;
  color: #e1e1df;
  font-family: Prata;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.knowhow_child_container h1 {
  margin: 0;
  color: #6df;
  font-family: Pretendard;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.knowhow_child_container h1:lang(en) {
  font-size: 3.75rem;
}
.knowhow_child_container p {
  color: #fefdfb;
  font-family: Pretendard;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.56rem;
  margin-bottom: 1.56rem;
}
.knowhow_child_container span {
  color: #fefdfb;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.main_profile_image {
  width: 40.625%;
  height: 500px;
  background-image: url(/public/image/pc/knowhow-img2.svg);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .brochure_m_br {
    display: block;
  }
  .brochure_pc_br {
    display: none;
  }
  .brochure_child_container {
    position: absolute;
    top: 327px;
    display: inline-block;
    text-align: center;
    padding: 0;
    width: 100%;
    min-width: auto;
  }
  .brocure_logo img {
    width: 144px;
    height: 111px;
  }
  .brochure_text {
    padding: 0;
    margin: 0;
    margin: auto;
  }
  .brochure_child_container h3 {
    color: #fff;
    text-align: center;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .brochure_child_container h3:lang(en) {
    color: #fff;
    text-align: center;
    font-family: Prata;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .brochure_child_container p {
    color: #fefefe;
    text-align: center;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .knowhow_container {
    display: inline-block;
    width: 100%;
    min-width: auto;
    height: auto;
    background: #2e2e2e;
  }
  .knowhow_container a {
    text-decoration: none;
  }
  .knowhow_child_container {
    margin: 0;
    padding: 0;
  }
  .knowhow_text {
    width: 86.67%;
    margin: auto;
  }
  .knowhow_child_container h6 {
    margin: 0;
    margin-top: 3.75rem;
    margin-bottom: 1.25rem;
    color: #e1e1df;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .knowhow_child_container h6:lang(en) {
    margin: 0;
    margin-top: 3.75rem;
    margin-bottom: 1.25rem;
    color: #e1e1df;
    font-family: Prata;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .knowhow_child_container h1 {
    margin: 0;
    color: #6df;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .knowhow_child_container h1:lang(en) {
    width: 20.4375rem;
    color: #6df;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .knowhow_child_container p {
    color: #fefdfb;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1.56rem;
    margin-bottom: 1.56rem;
  }
  .knowhow_child_container span {
    color: #fefdfb;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .main_profile_image {
    width: 100%;
    height: 251.92px;
    margin-top: 3.75rem;
    background-image: url(/public/image/mobile/knowhow_profile.svg);
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
