.custom-slider {
  min-width: 1280px;
  height: 1035px;
}
.main_banner1 {
  width: 100%;
  height: 1035px;
  background-image: url("/public/image/pc/banner1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.main_banner2 {
  width: 100%;
  height: 1035px;
  background-image: url("/public/image/pc/banner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.main_banner3 {
  width: 100%;
  height: 1035px;
  background-image: url("/public/image/pc/banner3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .custom-slider {
    min-width: auto;
    height: 800px;
  }
  .main_banner1 {
    width: 100%;
    height: 800px;
    background-image: url("/public/image/pc/banner1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .main_banner2 {
    width: 100%;
    height: 800px;
    background-image: url("/public/image/pc/banner2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .main_banner3 {
    width: 100%;
    height: 800px;
    background-image: url("/public/image/pc/banner3.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
