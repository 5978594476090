.not_found_pc_br {
  display: block;
}
.not_found_m_br {
  display: none;
}
.not_found_container {
  padding-top: 8.4375rem;
  text-align: center;
  width: 100%;
  min-width: 1280px;
  height: 48.1275rem;
}
.not_found_dot_container {
  margin-bottom: '0.31rem';
}
.not_found_container h2 {
  margin: 0;
  color: #232323;
  text-align: center;
  font-family: Pretendard;
  font-size: 3.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 118.836%; /* 4.60488rem */
}
.not_found_container p {
  margin: 0;
  margin-top: 3.75rem;
  color: #343434;
  text-align: center;
  font-family: Pretendard;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 118.836%; /* 2.37675rem */
}

@media screen and (max-width: 768px) {
  .not_found_pc_br {
    display: none;
  }
  .not_found_m_br {
    display: block;
  }
  .not_found_container {
    padding-top: 4.6875rem;
    text-align: center;
    width: 100%;
    min-width: auto;
    height: auto;
  }
  .not_found_dot_container {
    display: 'flex';
    margin-bottom: '0.31rem';
  }
  .not_found_container h2 {
    margin: 0;
    color: #232323;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 118.836%; /* 2.07963rem */
  }
  .not_found_container p {
    margin: 0;
    margin-top: 1.87rem;
    margin-bottom: 6.25rem;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    color: #343434;
    text-align: center;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 118.836%; /* 1.18838rem */
  }
}
