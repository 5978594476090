.header_container {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 1280px;
  height: 8.4375rem;
  /* background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  z-index: 999;
}
.header_container a {
  text-decoration: none;
}
.header_child_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}
.header_mobile_menu {
  display: none;
}
.header_nav_container {
  display: flex;
  align-items: center;
}

.header_container ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.87rem;
  padding: 0;
}
.header_container ol {
  /* color: #1b1b1b; */
  color: #fff;
  font-family: Pretendard;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 118.836%; /* 1.33688rem */
  text-align: center;
  padding: 0;
}
.header_modal {
  display: flex;
}

.header_lang_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.63rem;
  width: 5.8125rem;
  height: 1.5625rem;
  flex-shrink: 0;
  border-radius: 1.625rem;
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 118.836%; /* 1.18838rem */
  margin-left: 1.56rem;
}

@media screen and (max-width: 768px) {
  .header_container {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 360px;
    height: 60px;
    /* border: 1px solid red; */
    /* background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  }
  .header_container a {
    text-decoration: none;
  }
  .header_child_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
  .header_logo {
    margin-left: 1.25rem;
  }
  .header_mobile_menu {
    display: block;
    margin-right: 1.25rem;
  }
  .header_mobile_logo {
    display: block;
    margin-left: 1.25rem;
    /* margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); */
  }
  .header_nav_container {
    display: none;
  }

  .header_container ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3.75rem;
    /* width: 46.38rem; */
    padding: 0;
  }
  .header_container ol {
    color: #1b1b1b;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 118.836%; /* 1.33688rem */
    text-align: center;
    padding: 0;
  }
  .header_modal {
    display: flex;
    position: fixed; /* 화면에 고정 */
    top: 0; /* 상단에 위치 */
    left: -100%; /* 초기 위치를 왼쪽으로 숨김 */
    width: 100%; /* 화면 너비 */
    height: 100vh;
    background-color: white;
    transition: left 1s ease !important;
    z-index: 1000; /* 다른 요소 위에 표시 */
  }
  .hambuger_menu {
    position: relative;
  }
  .hambuger_close {
    position: absolute;
    top: 1.87rem;
    right: 1.25rem;
  }
  .header_modal.active {
    left: 0 !important; /* 화면에 표시 */
  }
  .hambuger_nav ul {
    margin: 0;
    margin-left: 2.5rem;
    margin-top: 4.69rem;
    padding: 0;
    display: block;
    list-style-type: none;
  }
  .hambuger_nav li {
    margin: 0;
    margin-bottom: 1.88rem;
    padding: 0;
    color: #232323;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 118.836%; /* 1.33688rem */
    text-decoration: none;
  }
  .hambuger_footer {
    margin: 0;
    /* margin-top: 22.5rem; */
    margin-top: 200px;
    padding-left: 2.5rem;
    color: #888;
    font-family: Pretendard;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 118.836%; /* 0.74275rem */
    text-transform: uppercase;
  }
  .hambuger_footer ul {
    display: flex;
    justify-content: start;
    gap: 0.94rem;
    margin: 0;
    padding: 0;
  }
  .hambuger_footer ol {
    margin: 0;
    padding: 0;
    text-decoration: underline;
  }
  .header_lang_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.63rem;
    width: 5.8125rem;
    height: 1.5625rem;
    flex-shrink: 0;
    border-radius: 1.625rem;
    border: 1px solid black;
    color: black;
    background-color: transparent;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 118.836%; /* 1.18838rem */
    margin-left: 1.56rem;
  }
}
