.clients_m_br {
  display: none;
}
.clients_pc_br {
  display: block;
}
.clients_container {
  width: 100%;
  min-width: 1280px;
  text-align: center;
  padding-top: 8.4375rem;
}
.clients_container h3 {
  margin: 0;
  margin-top: 6.88rem;
  color: #222;
  font-family: Pretendard;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.clients_list_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  width: 1279.58px;
  height: 410px;
  margin: auto;
  margin-top: 3.75rem;
}
.clients_list_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 calc(25% - 40px); /* PC에서 4열 */
  width: 244px;
  height: 50px;
}
.clients_vip_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 60px;
  width: 934px;
  height: 160px;
  margin: auto;
  margin-top: 3.75rem;
}
.clients_vip_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 calc(33.333% - 60px);
  width: 244px;
  height: 50px;
}
.clients_slider {
  margin: auto;
  margin-top: 2.81rem;
  margin-bottom: 6.87rem;
  position: relative;
  width: 1062px;
  height: 21.875rem;
}
.clients_slider_container {
  display: flex;
  text-align: left;
  background-image: url("/public/image/pc/clients-slide-back.svg");
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
  background-position: top right; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
}
.clients_slide_first_img {
  width: 30.6%;
  height: 350px;
  background-image: url("/public/image/pc/clients-slide-1.svg");
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
  background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
}
.clients_slide_second_img {
  width: 30.6%;
  height: 350px;
  background-image: url("/public/image/pc/clients-slide-2.svg");
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
  background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
}
.clients_slide_third_img {
  width: 30.6%;
  height: 350px;
  background-image: url("/public/image/pc/clients-slide-3.svg");
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
  background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
}
.clients_slider_container p {
  margin: 0;
  margin-top: 3.75rem;
  margin-left: 1.875rem;
  color: #222;
  font-family: Pretendard;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.clients_slider_index {
  display: flex;
  justify-content: space-between;
  width: 4.6875rem;
  height: 0.9375rem;
  margin: 0;
  margin-left: 30px;
  position: absolute;
  bottom: 5rem;
}
.slide_button {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background-color: #d9d9d9;
}
.left-arrow {
  position: absolute;
  top: 50%; /* 중앙 정렬 */
  left: -50px; /* 부모 요소의 왼쪽 바깥쪽으로 1.88rem 떨어지게 */
  transform: translate(-50%, -50%); /* Y축 중앙 정렬과 X축 조정 */
}
.left-arrow:hover {
  cursor: pointer;
}
.right-arrow {
  position: absolute;
  top: 50%; /* 중앙 정렬 */
  right: -50px; /* 부모 요소의 오른쪽 바깥쪽으로 1.88rem 떨어지게 */
  transform: translate(50%, -50%); /* Y축 중앙 정렬과 X축 조정 */
}
.right-arrow:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .clients_m_br {
    display: block;
  }
  .clients_pc_br {
    display: none;
  }
  .clients_container {
    width: 100%;
    min-width: auto;
    text-align: center;
    padding-top: 4.6875rem;
  }
  .clients_container h3 {
    margin: 0;
    margin-top: 3.75rem;
    color: #222;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .clients_container h3:nth-of-type(2),
  .clients_container h3:nth-of-type(3) {
    margin-top: 6.25rem;
  }
  .clients_list_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    width: 322.08px;
    height: auto;
    margin: auto;
    margin-top: 1.87rem;
  }
  .clients_list_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 calc(50% - 30px);
    width: 160px;
    height: 33px;
  }
  .clients_list_item img {
    width: 100%;
    width: auto;
    max-width: 100%;
    height: 33px;
  }
  .clients_vip_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    width: 322.08px;
    height: auto;
    margin: auto;
    margin-top: 1.87rem;
  }
  .clients_vip_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 calc(50% - 30px);
    width: 160px;
    height: 33px;
  }
  .clients_vip_item img {
    /* width: 100%; */
    width: auto;
    max-width: 100%;
    height: 33px;
  }
  .clients_slider {
    margin: auto;
    margin-top: 1.87rem;
    margin-bottom: 6.25rem;
    position: relative;
    width: 89.82%;
    height: 31.25rem;
  }
  .clients_slider_container {
    display: block;
    text-align: left;
    height: 31.25rem;
    background-image: url("/public/image/mobile/clients-slide-back.svg");
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: bottom right; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */

    background-image: url("/public/image/mobile/clients-slide-back.svg");
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: bottom right; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
  }
  .clients_slide_first_img {
    width: 100%;
    height: 250px;
    background-image: url("/public/image/mobile/clients-slide-first.svg");
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
  }
  .clients_slide_second_img {
    width: 100%;
    height: 250px;
    background-image: url("/public/image/mobile/clients-slide-second.svg");
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
  }
  .clients_slide_third_img {
    width: 100%;
    height: 250px;
    background-image: url("/public/image/mobile/clients-slide-third.svg");
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
  }
  .clients_slider_container p {
    margin: 0;
    margin-top: 1.88rem;
    padding: 0 0.62rem;
    color: #222;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .clients_slider_container p:lang(en) {
    margin: 0;
    margin-top: 1.88rem;
    padding: 0 0.62rem;
    color: #222;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .clients_slider_index {
    display: flex;
    justify-content: space-between;
    width: 4.6875rem;
    height: 0.9375rem;
    margin: 0;
    margin-left: 2.06rem;
    position: absolute;
    bottom: 3.75rem;
  }
  .slide_button {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    background-color: #d9d9d9;
  }
  .left-arrow {
    position: absolute;
    top: 50%; /* 중앙 정렬 */
    left: -50px; /* 부모 요소의 왼쪽 바깥쪽으로 1.88rem 떨어지게 */
    transform: translate(-50%, -50%); /* Y축 중앙 정렬과 X축 조정 */
  }
  .left-arrow:hover {
    cursor: pointer;
  }
  .right-arrow {
    position: absolute;
    top: 50%; /* 중앙 정렬 */
    right: -50px; /* 부모 요소의 오른쪽 바깥쪽으로 1.88rem 떨어지게 */
    transform: translate(50%, -50%); /* Y축 중앙 정렬과 X축 조정 */
  }
  .right-arrow:hover {
    cursor: pointer;
  }
}
