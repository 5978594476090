.aboutus_m_br {
  display: none;
}
.aboutus_pc_br {
  display: block;
}
.about_us_container {
  width: 100%;
  min-width: 1280px;
}
.about_us_banner {
  width: 100%;
  height: 1055px;
  overflow: hidden;
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
  background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
  background-image: url("/public/image/pc/about-us-banner.png");
}
.about_us_banner_text {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24.6275rem;
  padding-left: 20px;
}
.about_us_banner h3 {
  margin: 0;
  color: #fff;
  font-family: Pretendard;
  font-size: 2.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.about_us_banner h3:lang(en) {
  margin: 0;
  color: #fff;
  font-family: Prata;
  font-size: 2.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_us_banner h2 {
  margin: 0;
  margin-top: 2.5rem;
  color: #fff;
  font-family: Pretendard;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.about_us_banner h2:lang(en) {
  margin: 0;
  margin-top: 2.5rem;
  color: #fff;
  font-family: Pretendard;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.about_us_info {
  text-align: center;
  color: #232323;
  text-align: center;
  font-family: Pretendard;
  /* font-size: 1.75rem; */
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.about_us_info h6 {
  margin: 0;
  margin-top: 6.88rem;
}
.about_us_profile_container {
  display: flex;
  justify-content: center;
  width: 68.44%;
  margin: auto;
  margin-top: 6.88rem;
}
.about_us_profile {
  flex: 1;
  height: 31.25rem;
  background-image: url("/public/image/pc/aboutus-profile.svg");
  overflow: hidden;
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
  background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
}
.about_us_profile_info {
  flex: 1;
  margin-top: 8.69rem;
  margin-left: 2rem;
  width: 39rem;
}
.about_us_profile_info h3 {
  margin: 0;
  color: #131313;
  font-family: Pretendard;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.about_us_profile_info h3:lang(en) {
  margin: 0;
  color: #131313;
  font-family: Prata;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_us_profile_info h4 {
  margin: 0;
  margin-top: 1.25rem;
  color: #ff9000;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.about_us_profile_info h4:lang(en) {
  margin: 0;
  margin-top: 1.25rem;
  width: 486px;
  color: #ff9000;
  font-family: Prata;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_us_profile_info h5 {
  margin: 0;
  margin-top: 1.88rem;
  color: #232323;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* width: 34rem; */
}
.about_us_profile_info p {
  /* width: 38.4375rem; */
  margin: 0;
  margin-top: 1.25rem;
  color: #343434;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_us_contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 31.6875rem;
  background: #3c3c3c;
  text-align: center;
  margin-top: 6.81rem;
  border: 1px solid #3c3c3c;
}
.about_us_contact h2 {
  margin: 0;
  color: #fbfbfb;
  text-align: center;
  font-family: Pretendard;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.about_us_contact h2:lang(en) {
  margin: 0;
  color: #fff;
  text-align: center;
  font-family: Prata;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_us_contact p {
  margin: 0;
  margin-top: 1.56rem;
  color: #eee;
  text-align: center;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.about_us_contact_btn {
  width: 13.4375rem;
  height: 3.125rem;
  border-radius: 1.25rem;
  border: 2px solid #ff9000;
  color: #ff9000;
  background-color: #3c3c3c;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 3.13rem;
}
.about_us_contact_btn:hover {
  color: #fff;
  background: #ff9000;
  border: none;
}

@media screen and (max-width: 768px) {
  .aboutus_m_br {
    display: block;
  }
  .aboutus_pc_br {
    display: none;
  }
  .about_us_container {
    width: 100%;
    min-width: auto;
  }
  .about_us_banner {
    width: 100%;
    height: 792px;
    overflow: hidden;
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
    background-image: url("/public/image/mobile/about-us-banner.png");
  }
  .about_us_banner_text {
    max-width: auto;
    margin-left: 20px;
    margin-top: 21.75rem;
    padding: 0;
  }
  .about_us_banner h3 {
    margin: 0;
    color: #fff;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .about_us_banner h3:lang(en) {
    margin: 0;
    color: #fff;
    font-family: Prata;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .about_us_banner h2 {
    margin: 0;
    margin-top: 0.94rem;
    color: #fff;
    font-family: Pretendard;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .about_us_banner h2:lang(en) {
    margin: 0;
    margin-top: 0.75rem;
    color: #fff;
    font-family: Pretendard;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .about_us_info {
    width: 100%;
    text-align: center;
    color: #232323;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .about_us_info h6 {
    color: #232323;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-top: 3.75rem;
  }
  .about_us_info h6:lang(en) {
    color: #232323;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-top: 3.75rem;
  }
  .about_us_profile_container {
    display: inline-block;
    margin-top: 3.75rem;
    width: 100%;
    position: relative;
  }
  .about_us_profile {
    width: 89.82%;
    height: 21.875rem;
    margin: auto;
  }
  .about_us_profile_info {
    margin: auto;
    margin-top: 1.87rem;
    width: 89.82%;
  }
  .about_us_profile_info h3 {
    margin: 0;
    width: auto;
    color: #131313;
    font-family: Pretendard;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .about_us_profile_info h3:lang(en) {
    margin: 0;
    width: auto;
    color: #131313;
    font-family: Prata;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .about_us_profile_info h4 {
    margin: 0;
    width: auto;
    margin-top: 1.25rem;
    color: #ff9000;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .about_us_profile_info h4:lang(en) {
    margin: 0;
    width: 259px;
    margin-top: 1.25rem;
    color: #ff9000;
    font-family: Prata;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .about_us_profile_info h5 {
    margin: 0;
    width: auto;
    margin-top: 1.88rem;
    color: #232323;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .about_us_profile_info p {
    margin: 0;
    margin-top: 0.94rem;
    width: auto;
    color: #232323;
    font-family: Pretendard;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .about_us_contact {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 23.6875rem;
    background: #3c3c3c;
    text-align: center;
    margin-top: 3.75rem;
    border: none;
  }
  .about_us_contact h2 {
    margin: 0;
    color: #fbfbfb;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .about_us_contact h2:lang(en) {
    margin: 0;
    color: #fff;
    text-align: center;
    font-family: Prata;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .about_us_contact p {
    margin: 0;
    margin-top: 0.94rem;
    padding: 0 2.25rem;
    color: #eee;
    text-align: center;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .about_us_contact_btn {
    width: 12.09375rem;
    height: 2.8125rem;
    border-radius: 1.125rem;
    border: 2px solid #ff9000;
    color: #ff9000;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 3.75rem;
  }
}
