.sales_m_br {
  display: none;
}
.sales_pc_br {
  display: block;
}
.sales_container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1280px;
  height: 55.125rem;
}
.sales_container a {
  text-decoration: none;
}
.sales_child_container {
  width: 81%;
  max-width: 67.5rem;
  text-align: center;
  padding-top: 6.87rem;
}
.sales_child_container h3 {
  color: #232323;
  text-align: center;
  font-family: Pretendard;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.sales_child_container p {
  color: #232323;
  text-align: center;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.56rem;
}
.sales_child_container ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3.19rem;
  padding: 0;
  margin: 0;
  margin-top: 3.12rem;
}
.sales_child_container ol {
  color: #777;
  font-family: Pretendard;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 118.836%; /* 1.634rem */
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 3.12rem;
}
.sales_child_container ol:hover {
  cursor: pointer;
}

.sales_slider {
  position: relative;
  width: 100%;
  height: 27.4375rem;
}
.sales_slide_service {
  width: 32.41%;
  height: 439px;
  background-image: url("/public/image/pc/sales-service.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.sales_slide_director {
  width: 350px;
  height: 439px;
  /* height: auto; */
  background-image: url("/public/image/pc/sales-director.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.sales_slide_solution {
  width: 350px;
  height: 439px;
  background-image: url("/public/image/pc/sales-solution.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.left-arrow {
  position: absolute;
  top: 50%; /* 중앙 정렬 */
  left: -50px; /* 부모 요소의 왼쪽 바깥쪽으로 1.88rem 떨어지게 */
  transform: translate(-50%, -50%); /* Y축 중앙 정렬과 X축 조정 */
}
.left-arrow:hover {
  cursor: pointer;
}
.right-arrow {
  position: absolute;
  top: 50%; /* 중앙 정렬 */
  right: -50px; /* 부모 요소의 오른쪽 바깥쪽으로 1.88rem 떨어지게 */
  transform: translate(50%, -50%); /* Y축 중앙 정렬과 X축 조정 */
}
.right-arrow:hover {
  cursor: pointer;
}

.sales_slider_container {
  display: flex;
  text-align: left;
  width: 100%;
  height: auto;
  position: relative;
  background-image: url("/public/image/pc/sales-back.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
}
.sales_slide_content {
  width: 55.74%;
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}
.sales_slider_container h4 {
  color: #232323;
  font-family: Pretendard;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 5.63rem;
}
.sales_slider_container h4:lang(en) {
  color: #232323;
  font-family: Pretendard;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 4.25rem;
}
.sales_slider_container h1 {
  margin: 0;
  margin-top: 0.94rem;
  color: #232323;
  font-family: Pretendard;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sales_slider_container h1:lang(en) {
  margin: 0;
  margin-top: 0.94rem;
  color: #232323;
  font-family: Pretendard;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sales_slider_container p {
  margin: 0;
  margin-top: 1.56rem;
  color: #343434;
  font-family: Pretendard;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.sales_slider_container p:lang(en) {
  margin: 0;
  margin-top: 1.56rem;
  color: #343434;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.sales_slider_container span {
  position: absolute;
  display: flex;
  color: #343434;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  bottom: 5.31rem;
}
.sales_slider_container span:lang(en) {
  position: absolute;
  display: flex;
  color: #343434;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  bottom: 4.25rem;
}

@media screen and (max-width: 768px) {
  .sales_m_br {
    display: block;
  }
  .sales_pc_br {
    display: none;
  }
  .sales_container {
    display: inline-block;
    width: 100%;
    min-width: auto;
    height: auto;
    margin-bottom: 6.25rem;
  }
  .sales_container a {
    text-decoration: none;
  }
  .sales_child_container {
    width: 100%;
    text-align: center;
    padding-top: 3.75rem;
  }
  .sales_child_container h3 {
    color: #232323;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
  .sales_child_container p {
    color: #232323;
    text-align: center;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.94rem;
    padding: 0 2.7rem;
  }
  .sales_child_container ul {
    display: flex;
    justify-content: center;
    gap: 1.88rem;
    padding: 0;
    margin: 0;
    margin-top: 1.88rem;
  }
  .sales_child_container ol {
    color: #777;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 118.836%; /* 1.18838rem */
    text-align: center;
    padding: 0;
    margin: 0;
    margin-bottom: 2.5rem;
  }
  .sales_child_container ol:hover {
    cursor: pointer;
  }
  .sales_slider {
    position: relative;
    margin: auto;
    width: 100%;
    height: auto;
  }
  .sales_slide_service {
    width: 100vw;
    height: 17.25rem;
    background-image: url("/public/image/mobile/sales-service.svg");
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
    box-sizing: border-box;
  }
  .sales_slide_director {
    width: 100vw;
    height: 17.25rem;
    background-image: url("/public/image/mobile/sales-director.svg");
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
    box-sizing: border-box;
  }
  .sales_slide_solution {
    width: 100vw;
    height: 17.25rem;
    background-image: url("/public/image/mobile/sales-solution.svg");
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
    box-sizing: border-box;
  }
  .left-arrow {
    display: none;
    position: absolute;
    top: 50%; /* 중앙 정렬 */
    left: -50px; /* 부모 요소의 왼쪽 바깥쪽으로 1.88rem 떨어지게 */
    transform: translate(-50%, -50%); /* Y축 중앙 정렬과 X축 조정 */
  }
  .left-arrow:hover {
    cursor: pointer;
  }
  .right-arrow {
    display: none;
    position: absolute;
    top: 50%; /* 중앙 정렬 */
    right: -50px; /* 부모 요소의 오른쪽 바깥쪽으로 1.88rem 떨어지게 */
    transform: translate(50%, -50%); /* Y축 중앙 정렬과 X축 조정 */
  }
  .right-arrow:hover {
    cursor: pointer;
  }
  .sales_slider_container {
    display: inline-block;
    text-align: left;
    /* width: 89.82%; */
    width: 100%;
    height: 36.1875rem;
    position: relative;
    background-image: url("/public/image/mobile/sales-back.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
  }
  .sales_slider_container:lang(en) {
    display: inline-block;
    text-align: left;
    /* width: 89.82%; */
    width: 100%;
    height: 40.6875rem;
    position: relative;
    background-image: url("/public/image/mobile/sales-back.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
  }
  .sales_slide_content {
    width: 100%;
    padding-left: 0.94rem;
    padding-right: 0.94rem;
  }
  .sales_slider_container h4 {
    color: #232323;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-top: 1.88rem;
  }
  .sales_slider_container h4:lang(en) {
    color: #232323;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-top: 1.87rem;
  }
  .sales_slider_container h1 {
    margin: 0;
    margin-top: 1.25rem;
    color: #232323;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .sales_slider_container h1:lang(en) {
    margin: 0;
    margin-top: 1.25rem;
    color: #232323;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .sales_slider_container p {
    padding: 0;
    margin: 0;
    margin-top: 0.94rem;
    color: #343434;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
  .sales_slider_container p:lang(en) {
    padding: 0;
    margin: 0;
    margin-top: 0.94rem;
    color: #343434;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
  .sales_slider_container span {
    position: absolute;
    display: flex;
    color: #343434;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    bottom: 2.5rem;
  }
  .sales_slider_container span:lang(en) {
    position: absolute;
    display: flex;
    color: #343434;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    bottom: 2.5rem;
  }
}
