@font-face {
  font-family: "Prata";
  src: url(./fonts/Prata-Regular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Regular"),
    url(./fonts/woff2/Pretendard-Regular.woff2) format("woff2"),
    url(./fonts/woff/Pretendard-Regular.woff) format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-display: swap;
  src: local("Pretendard Medium"),
    url(./fonts/woff2/Pretendard-Medium.woff2) format("woff2"),
    url(./fonts/woff/Pretendard-Medium.woff) format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-display: swap;
  src: local("Pretendard SemiBold"),
    url(./fonts/woff2/Pretendard-SemiBold.woff2) format("woff2"),
    url(./fonts/woff/Pretendard-SemiBold.woff) format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-display: swap;
  src: local("Pretendard Bold"),
    url(./fonts/woff2-subset/Pretendard-Bold.subset.woff2) format("woff2"),
    url(./fonts/woff-subset/Pretendard-Bold.subset.woff) format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  font-display: swap;
  src: local("Pretendard ExtraBold"),
    url(./fonts/woff2-subset/Pretendard-ExtraBold.subset.woff2) format("woff2"),
    url(./fonts/woff-subset/Pretendard-ExtraBold.subset.woff) format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  font-display: swap;
  src: local("Pretendard Black"),
    url(./fonts/woff2-subset/Pretendard-Black.subset.woff2) format("woff2"),
    url(./fonts/woff-subset/Pretendard-Black.subset.woff) format("woff");
}

body {
  margin: 0;
  padding: 0;
  min-width: 360px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
