.service_pc_br {
  display: block;
}
.service_m_br {
  display: none;
}
.service_container {
  width: 100%;
  min-width: 1280px;
}
.service_banner {
  width: 100%;
  height: 1055px;
  overflow: hidden;
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
  background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
  background-image: url("/public/image/pc/service-banner.png");
}
.service_banner_text {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28.8375rem;
  padding-left: 20px;
}
.service_banner h3 {
  margin: 0;
  color: #fff;
  font-family: Pretendard;
  font-size: 2.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.service_banner h3:lang(en) {
  margin: 0;
  color: #eeebe3;
  font-family: Prata;
  font-size: 2.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.service_banner h2 {
  margin: 0;
  margin-top: 2.5rem;
  color: #fff;
  font-family: Pretendard;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.service_banner h2:lang(en) {
  margin: 0;
  margin-top: 2.5rem;
  color: #fff;
  font-family: Pretendard;
  font-size: 4.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.service_info {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 28.5rem;
  border: 1px solid red;
}
.service_info img {
  width: 304.4px;
  height: 236px;
  margin-right: 8.04rem;
  margin-top: 110px;
}
.service_info p {
  margin: 0;
  margin-top: 123px;
  color: #232323;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.service_info p:lang(en) {
  margin: 0;
  margin-top: 123px;
  color: #232323;
  font-family: Pretendard;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.service_info_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 1280px;
  height: 28.5rem;
}
.service_info_child_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1062px;
  padding: 0 20px;
}
.service_info_logo img {
  width: 304px;
  height: 236px;
}
.service_info_text {
  padding: 0;
  margin-left: 20px;
}
.service_info_container p {
  color: #343434;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.service_solution {
  width: 100%;
  height: 59.75rem;
  padding-top: 6.88rem;
  background-color: #ff9000;
}
.service_solution h3 {
  margin: 0;
  color: #232323;
  text-align: center;
  font-family: Pretendard;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.service_solution_back {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  margin: auto;
}
.service_solution_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 260px;
  margin-top: 3.75rem;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/public/image/pc/service-solution.svg);
}
.service_solution_img p {
  margin: 0;
  color: #fefdfb;
  text-align: center;
  font-family: Pretendard;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.service_solution_img p:lang(en) {
  margin: 0;
  color: #fefdfb;
  text-align: center;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.service_marketing_container {
  width: 100%;
  min-width: 1280px;
  height: 58.13rem;
}
.service_marketing_container h3 {
  margin: 0;
  margin-top: 6.88rem;
  color: #232323;
  text-align: center;
  font-family: Pretendard;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.service_marketing_step_container {
  display: flex;
  justify-content: center;
  min-width: 1280px;
  gap: 1.25rem;
  margin-top: 3.75rem;
}
.service_marketing_step {
  text-align: center;
}
.service_marketing_step h6 {
  margin: 0;
  color: #ff9000;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.service_marketing_step h5 {
  margin: 0;
  margin-top: 0.94rem;
  color: #343434;
  font-family: Pretendard;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.service_marketing_step h5:lang(en) {
  margin: 0;
  margin-top: 0.94rem;
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.service_marketing_step_content {
  text-align: left;
  height: 28.5rem;
  background: #3c3c3c;
  margin-top: 1.88rem;
  padding-top: 3.75rem;
  padding-left: 1.36rem;
}
.service_marketing_step_content:lang(en) {
  text-align: left;
  height: 33.75rem;
  background: #3c3c3c;
  margin-top: 1.88rem;
  padding-top: 3.75rem;
  padding-left: 1rem;
}
.service_marketing_step_content ul {
  width: 15.1875rem;
  height: 21rem;
  color: #fefdfb;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.service_marketing_step_content ul:lang(en) {
  width: 15.1875rem;
  height: 21rem;
  color: #fefdfb;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .service_pc_br {
    display: none;
  }
  .service_m_br {
    display: block;
  }
  .service_container {
    width: 100%;
    padding: 0;
    margin-bottom: 6.25rem;
    min-width: auto;
  }
  .service_banner {
    width: 100%;
    height: 800px;
    margin-bottom: 60px;
    overflow: hidden;
    background-repeat: no-repeat; /* 이미지 반복 방지 */
    background-size: cover; /* 이미지가 컨테이너를 가득 채우도록 설정 */
    background-position: center; /* 이미지가 컨테이너 중앙에 위치하도록 설정 */
    background-image: url("/public/image/mobile/service-banner.png");
  }
  .service_banner_text {
    max-width: auto;
    margin-top: 21.75rem;
    margin-left: 1.25rem;
    padding: 0;
  }
  .service_banner h3 {
    margin: 0;
    color: #fff;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .service_banner h3:lang(en) {
    margin: 0;
    color: #eeebe3;
    font-family: Prata;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .service_banner h2 {
    margin: 0;
    margin-top: 0.94rem;
    color: #fff;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .service_banner h2:lang(en) {
    margin: 0;
    margin-top: 0.94rem;
    color: #fff;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .service_info {
    display: inline-block;
    width: 100%;
    height: 39.3125rem;
    text-align: center;
  }
  .service_info img {
    width: auto;
    height: auto;
    margin-top: 3.75rem;
    margin-left: auto;
    margin-right: auto;
  }
  .service_info p {
    margin: 0;
    margin-top: 3.75rem;
    padding: 0 2.06rem;
    color: #232323;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .service_info_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: auto;
    height: 23.69rem;
  }
  .service_info_container:lang(en) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: auto;
    height: 600px;
  }
  .service_info_child_container {
    display: inline-block;
    text-align: center;
    padding: 0;
  }
  .service_info_logo img {
    width: 144px;
    height: 111px;
  }
  .service_info_text {
    padding: 0;
    margin: 0;
    margin: auto;
    width: 69.72%;
  }
  .service_info_text:lang(en) {
    padding: 0;
    margin: 0;
    margin: auto;
    width: 100%;
  }
  .service_info_container p {
    color: #343434;
    text-align: center;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .service_info_container p:lang(en) {
    width: 20.375rem;
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: auto;
    margin-right: auto;
  }

  .service_solution {
    width: 100%;
    height: auto;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    margin-top: 3.75rem;
    background-color: #ff9000;
    text-align: center;
  }
  .service_solution h3 {
    margin: 0;
    margin-bottom: 3.75rem;
    color: #232323;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .service_solution_back {
    display: block;
    text-align: center;
  }
  .service_solution_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 173.43px;
    margin-top: 1.87rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/public/image/mobile/service-solution.svg);
  }
  .service_solution_img:lang(en) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 260px;
    margin-top: 1.87rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/public/image/mobile/service-solution-en.svg);
  }
  .service_solution_img p {
    margin: 0;
    color: #fefdfb;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .service_solution_img p:lang(en) {
    margin: 0;
    color: #fefdfb;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .service_solution_img span {
    color: #6df;
  }

  .service_marketing_container {
    width: 100%;
    min-width: auto;
    height: auto;
    text-align: center;
  }
  .service_marketing_container h3 {
    margin: 0;
    margin-top: 3.75rem;
    padding: 0;
    color: #232323;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .service_marketing_container h3:lang(en) {
    margin: 0;
    margin-top: 3.75rem;
    padding: 0;
    color: #232323;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .service_marketing_step_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: auto;
    flex-wrap: wrap;
    gap: 3.75rem;
    margin-top: 3.75rem;
  }
  .service_marketing_step {
    text-align: center;
    width: 100%;
  }
  .service_marketing_step h6 {
    margin: 0;
    color: #ff9000;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .service_marketing_step h5 {
    margin: 0;
    margin-top: 0.94rem;
    margin-bottom: 2.87rem;
    color: #343434;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .service_marketing_step h5:lang(en) {
    margin: 0;
    margin-top: 0.94rem;
    margin-bottom: 2.87rem;
    color: #343434;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .service_marketing_step_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 20rem;
    height: 19.375rem;
    background: #3c3c3c;
    margin: 0;
    margin-top: 2.87rem;
    margin: auto;
    padding-top: 0rem;
  }
  .service_marketing_step_content:lang(en) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 20rem;
    height: 26.5rem;
    background: #3c3c3c;
    margin: 0;
    margin-top: 2.87rem;
    margin: auto;
    padding-top: 0rem;
  }
  .service_marketing_step_content ul {
    width: auto;
    height: auto;
    color: #fefdfb;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    padding-left: 0.5rem;
    margin: 0;
  }
  .service_marketing_step_content ul:lang(en) {
    width: 18.125rem;
    height: 19rem;
    color: #fefdfb;
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
